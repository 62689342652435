.react-responsive-modal-modal {
	padding: 32px;
	border-radius: 10px;
}

.pop-up-modal-text {
	margin: 0;
	background: #fff;
	border-radius: 5px;
	font-size: 24px;
	color: #928b75;
	text-align: center;
}

.react-responsive-modal-modal p {
	margin: 0;
}

.react-responsive-modal-modal button {
	outline: none;
	border: none;
}

.pop-up-buttons {
	a {
		color: #fff;
		padding: 12px 32px;
		background-color: #a8903f; 
		width: 300px;
	}
}