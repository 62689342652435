@import '../../css/variables';

$bottom-spacing: 30px;

.careers__container {
  padding: 15px 30px;
  width: 100%;
}

.careers__title {
  text-align: center;
}

.careers__name,
.careers__persons--info,
.careers__employment,
.careers__positions {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(1, 100%);
  margin-bottom: $bottom-spacing;
}

.careers__availability--date,
.careers__haveApplied,
.careers__legallyAllowedToWorkInUS,
.careers__work--company {
  margin-bottom: $bottom-spacing;
}

.careers__availability--days {
  @extend .careers__name;
  margin-bottom: $bottom-spacing;
}

.careers__availability--day {
  display: inline-grid;
  grid-row-gap: 5px;
}

.careers__availability--times {
  margin-left: 10px;
  font-style: italic;
  font-size: 13px;
  display: inline-block;
}

.careers__haveApplied > :first-child,
.careers__legallyAllowedToWorkInUS > :first-child {
  margin-right: 40px;
}

.careers__highschool,
.careers__college,
.careers__signature {
  @extend .careers__name;
}

.careers__highschoolStatus,
.careers__collegeStatus {
  @extend .careers__name;
  grid-template-columns: repeat(3, 33.3%);
}

.careers__submitMessage {
  font-size: 25px;
  color: white;
  text-align: center;
  background: transparent;
  box-shadow: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  
}

@media (min-width: 600px) {
  .careers__container {
    padding: 30px 50px;
  }
  .careers__name,
.careers__persons--info,
.careers__employment,
.careers__positions {
  grid-template-columns: repeat(2, 50%);
}

}

@media (min-width: 800px) {
  .careers__container {
    padding: 30px 100px;
  }
}

@media (min-width: 1000px) {
  .careers__container {
    padding: 30px 150px;
  }
}
