@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

body {
  margin: 0;
  background: #fff;
  color: #212121;
  
}

html {
  --scrollbarBG: rgb(243, 243, 243);
  --thumbBG: #212121e1;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #212121e1 rgb(243, 243, 243);
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: rgb(243, 243, 243);
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: #212121e1;
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid rgb(243, 243, 243);
  border: 3px solid var(--scrollbarBG);
}
/*# sourceMappingURL=App.css.map */
.nav-one nav {
  height: 100px;
  padding-top: 20px;
}

.nav-one .nav-appear {
  position: fixed;
  width: 100%;
  top: -160px;
  z-index: 9999;
  -webkit-transition: 1s;
  transition: 1s;
  background-color: white !important;
  color: #323e37 !important;
  height: 145px;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  /* NAVIGATION */
  /* SHIFT */
  /* Keyframes */
}

.nav-one .nav-appear .menu-section {
  height: 20px;
  width: auto;
}

.nav-one .nav-appear .logo-section {
  height: 60px;
  text-align: center;
  width: auto;
}

.nav-one .nav-appear .logo-section .social-media {
  position: relative;
  /* float: right; */
  top: -20px;
  border-top-right-radius: 90px;
  height: 100px;
  padding-left: 50px;
  width: 220px;
  padding-top: 32px;
}

.nav-one .nav-appear .logo-section .social-media i {
  color: #A8903F;
  padding: 0;
}

.nav-one .nav-appear .nav-wrapper {
  z-index: 999;
  width: 100%;
  margin: 0 auto;
  color: #A8903F;
}

.nav-one .nav-appear .order {
  float: left;
  color: #A8903F !important;
  padding: 30px 30px 7px 30px !important;
  font-weight: bolder;
  position: relative;
  left: 56px;
  top: -23px !important;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  letter-spacing: 0.2em;
  background: transparent;
  border: none;
}

.nav-one .nav-appear .order:hover {
  background: #A8903F !important;
  color: white !important;
  padding: 23px 30px 15px 30px !important;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 5px 8px rgba(0, 0, 0, 0.1);
}

.nav-one .nav-appear .divide {
  position: relative;
  height: 30px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.199) !important;
  top: 16px;
}

.nav-one .nav-appear .logo-img {
  position: relative;
  height: 68px;
  top: 0px;
}

.nav-one .nav-appear .m4 a {
  color: #A8903F;
  top: 0px;
}

.nav-one .nav-appear ul li a:hover {
  text-decoration: none !important;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 5px 8px rgba(0, 0, 0, 0.1);
}

.nav-one .nav-appear .transparent {
  box-shadow: none !important;
}

.nav-one .nav-appear div.nav-menu a {
  list-style: none;
  text-align: center;
  display: inline-block;
  color: #928b75;
}

.nav-one .nav-appear div.nav-menu a {
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 10px;
}

.nav-one .nav-appear div.nav-menu a,
.nav-one .nav-appear div.nav-menu a:after,
.nav-one .nav-appear div.nav-menu a:before {
  -webkit-transition: all .5s;
  transition: all .5s;
}

.nav-one .nav-appear div.nav-menu a:hover {
  color: #555;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 5px 8px rgba(0, 0, 0, 0.1);
}

.nav-one .nav-appear div.shift a {
  position: relative;
  z-index: 1;
}

.nav-one .nav-appear div.shift a:hover {
  color: white;
  font-weight: normal;
}

.nav-one .nav-appear div.shift a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: '.';
  color: transparent;
  background: #928b75;
  visibility: none;
  opacity: 0;
  z-index: -1;
}

.nav-one .nav-appear div.shift a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

.nav-one .row {
  margin: 0 !important;
  padding: 0 !important;
}

.nav-menu {
  background: #f0f0f0 !important;
  z-index: 1;
}

.nav-menu.m4 {
  position: relative !important;
  top: -35px !important;
}

.nav-menu.m4 a {
  padding: 0 40px 0 40px !important;
  letter-spacing: 0.3em;
}

@media only screen and (max-width: 1000px) {
  .nav-appear {
    display: none !important;
  }
}
/*# sourceMappingURL=NavOne.css.map */
.order-frame {
  width: 1000px;
  height: 600px;
  border: none;
  box-shadow: none !important;
  background: white;
}

.styles_overlay__CLSq- {
  z-index: 9999;
}

.styles_modal__gNwvD {
  text-align: center;
  /* background: transparent !important; */
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
          border-radius: 5px;
}

.styles_modal__gNwvD .loader {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.styles_modal__gNwvD button {
  background: transparent !important;
  top: 37px !important;
  right: 37px !important;
  display: none;
}

@media (max-width: 768px) {
  .order-frame {
    width: 600px;
    height: 530px;
  }

  .pop-up-buttons {
    flex-direction: column;
    grid-row-gap: 16px;
    row-gap: 16px;
  }
}

@media (max-width: 600px) {
  .order-frame {
    width: 350px;
    height: 600px;
  }
}
/*# sourceMappingURL=Popup.css.map */

.modal {
  position: fixed;
  background-color: white;
}

.popup-title {
  color: #a8903f;
  font-size: 16px;
}

.pop-up-buttons {
  text-align: center;
  display: flex;
}
.pop-up-buttons	a {
  font-size: 16px;
	color: #a8903f;
	padding: 12px 32px;
	border: 2px solid #a8903f;
	width: 150px;
  outline: none;
  margin: 0 8px;
  border-radius: 5px;
  text-transform: uppercase;
}

.pop-up-buttons	a:hover {
  font-size: 16px;
	color: #fff;
	padding: 12px 32px;
	border: 2px solid #a8903f;
  background-color: #a8903f;
}

.parallax-one .next-section.container, .parallax-one .section-2, .parallax-one .section-3 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.parallax-one {
  color: #fff;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
}

.parallax-one .container {
  width: 50%;
}

.parallax-one .section {
  margin: auto;
}

.parallax-one #index-banner {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(26, 26, 26, 0.8)), to(rgba(0, 0, 0, 0.4)));
  background: linear-gradient(rgba(26, 26, 26, 0.8), rgba(0, 0, 0, 0.4));
}

.parallax-one .parallax-container {
  position: relative;
  text-align: center;
  height: 100vh;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(40, 40, 40, 0)), to(rgba(40, 40, 40, 0.5)));
  background: linear-gradient(rgba(40, 40, 40, 0), rgba(40, 40, 40, 0.5));
  background-blend-mode: hard-light;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  /* NAVIGATION */
  /* SHIFT */
  /* Keyframes */
}

.parallax-one .parallax-container .box-gallery {
  z-index: -1 !important;
}

.parallax-one .parallax-container .blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.parallax-one .parallax-container .header {
  position: absolute;
  top: 46%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}

.parallax-one .parallax-container .header h1 {
  color: #ffffff;
  font-size: 80px;
  letter-spacing: .05em;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
}

.parallax-one .parallax-container .header .order {
  padding: 15px 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-weight: bolder;
  border-radius: 5px;
  border: 1px solid white;
  box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0.75);
}

.parallax-one .parallax-container .header .order:hover {
  border: 1px solid #928b75;
  color: white !important;
  box-shadow: 0 20px 50px -14px rgba(0, 0, 0, 0.75);
  font-weight: normal;
}

.parallax-one .parallax-container .header ul li .order {
  font-size: 14px !important;
  letter-spacing: 0.5em;
}

.parallax-one .parallax-container .social i {
  padding: 11px 12px 11px 12px;
  font-size: 28px;
  color: white;
  border: 1px solid white;
  margin: 0 10px 0 10px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0.75);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 999;
}

.parallax-one .parallax-container .social i:hover {
  border: 1px solid #928b75;
  color: #928b75;
}

.parallax-one .parallax-container .logo {
  width: 43%;
  height: auto;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.parallax-one .parallax-container ul li {
  display: inline;
  list-style-type: none;
}

.parallax-one .parallax-container ul li a {
  color: #fff !important;
}

.parallax-one .parallax-container div.links ul {
  list-style: none;
  text-align: center;
}

.parallax-one .parallax-container div.links ul li {
  display: inline-block;
}

.parallax-one .parallax-container div.links ul li a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #aaa;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 10px;
}

.parallax-one .parallax-container div.links ul li a,
.parallax-one .parallax-container div.links ul li a:after,
.parallax-one .parallax-container div.links ul li a:before {
  -webkit-transition: all .5s;
  transition: all .5s;
}

.parallax-one .parallax-container div.links ul li a:hover {
  color: #555;
}

.parallax-one .parallax-container div.shift ul li a {
  position: relative;
  z-index: 1;
}

.parallax-one .parallax-container div.shift ul li a:hover {
  color: white;
  font-weight: normal;
}

.parallax-one .parallax-container div.shift ul li a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: '.';
  color: transparent;
  background: #928b75;
  visibility: none;
  opacity: 0;
  z-index: -1;
}

.parallax-one .parallax-container div.shift ul li a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

@keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

.parallax-one .next-section.container {
  color: black;
  height: 100vh;
  width: 100vw;
}

.parallax-one .section-2 {
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: soft-light;
  font-size: 17px;
}

.parallax-one .section-3 {
  background-image: -webkit-gradient(linear, left top, right bottom, from(#666051), color-stop(#6e6858), color-stop(#76705e), color-stop(#7f7865), color-stop(#87806c), color-stop(#8d8671), color-stop(#938c76), color-stop(#99927b), color-stop(#9e977f), color-stop(#a49c84), color-stop(#a9a288), to(#afa78d));
  background-image: linear-gradient(to right bottom, #666051, #6e6858, #76705e, #7f7865, #87806c, #8d8671, #938c76, #99927b, #9e977f, #a49c84, #a9a288, #afa78d);
  color: white;
  font-size: 20px;
}

.parallax-one .section-3 .section3-img {
  width: 400px;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.75);
}

.parallax-one .section-3 h2 {
  color: white;
}

.parallax-one .section-3 .container {
  width: 75% !important;
}

.parallax-one .section-4 {
  /* Common style */
  /* Anchor will cover the whole item by default */
  /* For some effects it will show as a button */
}

.parallax-one .section-4 .row {
  margin: 0;
}

.parallax-one .section-4 .row .col {
  padding: 0;
  margin: 0;
}

.parallax-one .section-4 .row .col .m6 {
  padding: 0 !important;
  margin: 0 !important;
}

.parallax-one .section-4 .grid {
  position: relative;
  margin: 0 auto;
  list-style: none;
  text-align: center;
}

.parallax-one .section-4 .grid figure {
  position: relative;
  overflow: hidden;
  margin: 0px;
  max-height: 480px;
  height: 350px;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.parallax-one .section-4 .grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.parallax-one .section-4 .grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.parallax-one .section-4 .grid figure figcaption::before,
.parallax-one .section-4 .grid figure figcaption::after {
  pointer-events: none;
}

.parallax-one .section-4 .grid figure figcaption,
.parallax-one .section-4 .grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.parallax-one .section-4 .grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.parallax-one .section-4 .grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.parallax-one .section-4 .grid figure h2 span {
  font-weight: 800;
}

.parallax-one .section-4 .grid figure h2,
.parallax-one .section-4 .grid figure p {
  margin: 0;
}

.parallax-one .section-4 .grid figure p {
  letter-spacing: 1px;
  font-size: 88.5%;
}

.parallax-one .section-4 figure.effect-lexi {
  background: linear-gradient(-45deg, #000 0%, #fff 100%);
}

.parallax-one .section-4 figure.effect-lexi img {
  margin: -10px 0 0 -10px;
  max-width: none;
  width: calc(100% + 40px);
  opacity: 0.9;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(10px, 10px, 0);
  transform: translate3d(10px, 10px, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.parallax-one .section-4 figure.effect-lexi .cocktail-img {
  top: 0px;
}

.parallax-one .section-4 figure.effect-lexi figcaption::before,
.parallax-one .section-4 figure.effect-lexi p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}

.parallax-one .section-4 figure.effect-lexi figcaption::before {
  position: absolute;
  right: -120px;
  bottom: -120px;
  width: 400px;
  height: 400px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 900px rgba(255, 255, 255, 0.2);
  content: '';
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 1);
  transform: scale3d(0.5, 0.5, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.parallax-one .section-4 figure.effect-lexi:hover img {
  opacity: 0.6;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.parallax-one .section-4 figure.effect-lexi h2 {
  text-align: left;
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(5px, 5px, 0);
  transform: translate3d(5px, 5px, 0);
}

.parallax-one .section-4 figure.effect-lexi p {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 200px;
  text-align: right;
  opacity: 0;
  -webkit-transform: translate3d(20px, 20px, 0);
  transform: translate3d(20px, 20px, 0);
}

.parallax-one .section-4 figure.effect-lexi:hover figcaption::before {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.parallax-one .section-4 figure.effect-lexi:hover h2,
.parallax-one .section-4 figure.effect-lexi:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.parallax-one .section-5 {
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: lighten;
  color: white !important;
  font-size: 17px;
  text-align: left;
}

.parallax-one .section-5 .m6 {
  padding: 0;
}

.parallax-one .section-5 .menu-btn {
  padding: 15px 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-weight: bolder;
  border-radius: 5px;
  border: 1px solid white;
  box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0.75);
  color: white;
  display: block;
  text-align: center;
  text-transform: uppercase;
}

.parallax-one .section-5 .menu-btn:hover {
  border: 1px solid #928b75;
  color: white !important;
  box-shadow: 0 20px 50px -14px rgba(0, 0, 0, 0.75);
  font-weight: normal;
  color: #928b75 !important;
}

.parallax-one .section-6 {
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: lighten;
  color: white;
  font-size: 17px;
  text-align: left;
}

.parallax-one .section-6 .m6 {
  padding: 0;
}

.parallax-one .section-6 a {
  text-decoration: none !important;
}

.parallax-one .section-6 .toast-tab {
  display: none !important;
}

.parallax-one .section-6 .order {
  padding: 15px 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-weight: bolder;
  border-radius: 5px;
  border: 1px solid white;
  box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0.75);
  color: white;
  display: block;
  text-align: center;
}

.parallax-one .section-6 .order:hover {
  border: 1px solid #928b75;
  color: white !important;
  box-shadow: 0 20px 50px -14px rgba(0, 0, 0, 0.75);
  font-weight: normal;
  color: #928b75 !important;
}

.parallax-one .section-6 .toast {
  display: none;
}

.parallax-one .section-7 .row {
  margin: 0;
}

.parallax-one .section-7 .contact {
  background: #A8903F !important;
  height: 400px;
  padding: 40px;
}

.parallax-one .section-7 .hours {
  height: 400px;
  padding: 40px;
}

@media only screen and (max-width: 1024px) and (min-width: 899px) {
  .logo {
    width: 60% !important;
  }
}

@media only screen and (max-width: 900px) and (min-width: 768px) {
  .logo {
    width: 70% !important;
  }
}

@media only screen and (max-width: 600px) {
  .parallax-one {
    height: 100%;
    text-align: center;
  }
  .parallax-one .container {
    width: 80% !important;
  }
  .parallax-one .container h5 {
    font-size: 18px;
  }
  .parallax-one .container .logo {
    width: 350px;
    height: auto;
  }
  .parallax-one .container .arrow {
    display: none;
  }
  .parallax-one .parallax-container .social i {
    padding: 5px 7px 5px 7px;
    font-size: 20px;
    border: none;
    margin: 17px 8px 0 8px;
    width: 30px;
    height: 30px;
    box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0.75);
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .parallax-one .parallax-container .social i:hover {
    border: 1px solid #928b75;
    color: #928b75;
  }
  .parallax-one .parallax-container img.responsive-img {
    max-width: 500px !important;
  }
  .parallax-one .parallax-container .links {
    display: none;
  }
  .parallax-one .section-3 .container {
    width: 80% !important;
  }
  .parallax-one .section-3 .container h5 {
    font-size: 18px;
  }
  .parallax-one .section-6 .order {
    display: none;
  }
  .parallax-one .section-6 .toast-tab {
    display: block !important;
  }
  .parallax-one .section-4 {
    /* Common style */
  }
  .parallax-one .section-4 .grid figure {
    max-height: 240px;
    height: auto;
  }
  .parallax-one .section-4 figure.effect-lexi img {
    top: 0;
    height: 100% !important;
  }
  .parallax-one .section-4 .grid figure h2 {
    top: -30px;
    position: relative;
    text-align: center !important;
  }
  .parallax-one .section-4 .grid figure p {
    font-size: 13px;
  }
  .parallax-one .section-4 figure.effect-lexi figcaption::before {
    position: absolute;
    right: -120px;
    bottom: -120px;
    width: 350px;
    height: 300px !important;
  }
  .parallax-one .section-4 figure.effect-lexi p {
    position: absolute;
    right: 20px;
    bottom: 10px;
    width: 150px;
  }
}

@media only screen and (max-width: 500px) {
  .parallax-one .top .container {
    width: 100% !important;
  }
  .social {
    float: right;
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .parallax-one .container {
    width: 70%;
  }
  .parallax-one .container h5 {
    font-size: 18px;
  }
  .parallax-one .links {
    display: none;
  }
  .parallax-one .section-3 .container {
    width: 70% !important;
  }
  .parallax-one .section-3 .container img {
    width: 100%;
    margin: 0 auto;
  }
  .parallax-one .section-3 .container h5 {
    font-size: 18px;
  }
  .parallax-one .section-3 .container h2 {
    font-size: 32px;
  }
}
/*# sourceMappingURL=ParallaxOne.css.map */
.react-responsive-modal-modal {
	padding: 32px;
	border-radius: 10px;
}

.pop-up-modal-text {
	margin: 0;
	background: #fff;
	border-radius: 5px;
	font-size: 24px;
	color: #928b75;
	text-align: center;
}

.react-responsive-modal-modal p {
	margin: 0;
}

.react-responsive-modal-modal button {
	outline: none;
	border: none;
}

.pop-up-buttons {
	a {
		color: #fff;
		padding: 12px 32px;
		background-color: #a8903f; 
		width: 300px;
	}
}
.nav-link-color-white {
  color: white !important;
  font-size: 14px !important;
}

.nav-link-color-black {
  color: #928b75 !important;
  font-size: 14px !important;
}

ul li a {
  letter-spacing: 0.1em;
}

ul li a:hover {
  text-decoration: none !important;
  background: none !important;
}

nav {
  background: transparent !important;
  position: fixed;
  box-shadow: none !important;
  z-index: 999;
  top: 0px;
  /* NAVIGATION */
  /* SHIFT */
  /* Keyframes */
}

nav .nav-wrapper {
  padding: 0 16px;
  margin: 0 auto;
}

@media (max-width: 1300px) {
  nav .nav-wrapper {
    padding: 0 16px;
  }
}

nav .links ul {
  list-style: none;
  text-align: center;
}

nav .links ul li {
  display: inline-block;
}

nav .links ul li a {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}

nav .links ul li a,
nav .links ul li a:after,
nav .links ul li a:before {
  -webkit-transition: all .5s;
  transition: all .5s;
}

nav .links ul li a:hover {
  color: #555;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 5px 8px rgba(0, 0, 0, 0.1);
}

nav .shift ul li a {
  position: relative;
  z-index: 1;
}

nav .shift ul li a:hover {
  color: white !important;
  font-weight: normal;
}

nav .shift ul li a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: '.';
  color: transparent;
  background: #928b75;
  visibility: none;
  opacity: 0;
  z-index: -1;
}

nav .shift ul li a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 5px 8px rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #333;
  }
}

.nav-background {
  background: #fff !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.nav-background .logo {
  width: 310px;
  height: auto;
  -webkit-filter: brightness(1.4);
          filter: brightness(1.4);
  height: auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.nav-background .logo-shrink {
  width: 210px;
  height: auto;
  height: auto;
  -webkit-filter: brightness(1);
          filter: brightness(1);
}

@-webkit-keyframes leaves {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}

@keyframes leaves {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}

.nav-background .divide2 {
  height: 30px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.199) !important;
  padding: 0px !important;
  margin: 0px !important;
  position: relative;
  top: 16px;
}

@media only screen and (max-width: 1000px) {
  .nav-background nav {
    display: none !important;
  }
}
/*# sourceMappingURL=NavTwo.css.map */
.parallax-two .section-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.parallax-two {
  width: 100%;
  overflow-x: hidden;
}

.parallax-two .header {
  background: url(/static/media/19.ea109600.jpg) center center;
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
  background-size: cover;
  z-index: -1;
  height: 50vh;
  color: white !important;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: soft-light;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parallax-two .header h1 {
  color: white;
  font-size: 40px;
  letter-spacing: 0.3em;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
}

.parallax-two .header .section2 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: black;
}

.parallax-two .header .section2 a {
  color: white;
}

.parallax-two .header .section2 ul li {
  display: inline;
  list-style-type: none;
  margin: 60px 20px 60px 20px;
}

.parallax-two .section-1 {
  color: #928b75;
  background: #f7f7f7 !important;
  font-size: 20px;
}

.parallax-two .section-1 .container {
  width: 50%;
}

.parallax-two .bg-darken {
  background-color: rgba(0, 0, 0, 0.609);
  background-blend-mode: lighten;
}

.parallax-two .bg-darken .section-3 {
  color: white;
}

.parallax-two .row {
  margin: 0px !important;
}

.parallax-two .pub2 {
  height: 350px;
  background-image: -webkit-gradient(linear, right bottom, left top, from(#666051), color-stop(#6e6858), color-stop(#76705e), color-stop(#7f7865), color-stop(#87806c), color-stop(#8d8671), color-stop(#938c76), color-stop(#99927b), color-stop(#9e977f), color-stop(#a49c84), color-stop(#a9a288), to(#afa78d));
  background-image: linear-gradient(to left top, #666051, #6e6858, #76705e, #7f7865, #87806c, #8d8671, #938c76, #99927b, #9e977f, #a49c84, #a9a288, #afa78d);
  text-align: center;
  color: white;
  width: 100%;
  border: 1px dotted white;
}

.parallax-two .pub2 h3 {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
}

.parallax-two .pub2 .pub2-container {
  margin: 0;
  position: relative;
  top: 43%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.parallax-two .pub2-image {
  background-size: cover;
  height: 350px;
  border: 1px dotted white;
}

.parallax-two .wine2 {
  height: 350px;
  text-align: center;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#666051), color-stop(#6e6858), color-stop(#76705e), color-stop(#7f7865), color-stop(#87806c), color-stop(#8d8671), color-stop(#938c76), color-stop(#99927b), color-stop(#9e977f), color-stop(#a49c84), color-stop(#a9a288), to(#afa78d));
  background-image: linear-gradient(to right bottom, #666051, #6e6858, #76705e, #7f7865, #87806c, #8d8671, #938c76, #99927b, #9e977f, #a49c84, #a9a288, #afa78d);
  color: white;
  border: 1px dotted white;
}

.parallax-two .wine2 h3 {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
}

.parallax-two .wine2 .wine2-container {
  margin: 0;
  position: relative;
  top: 43%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.parallax-two .wine2-image {
  background-size: cover;
  height: 350px;
  border: 1px dotted white;
}

.parallax-two .section-5 {
  color: white;
}

.parallax-two .section-5 .row {
  margin: 0;
}

.parallax-two .section-5 .contact {
  background: #A8903F !important;
  height: 400px;
  padding: 40px;
}

.parallax-two .section-5 .hours {
  height: 400px;
  padding: 40px;
}

@media screen and (max-width: 700px) {
  .parallax-two .container {
    width: 80% !important;
  }
}
/*# sourceMappingURL=ParallaxTwo.css.map */
@charset "UTF-8";
.menu-list .header-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.flex img {
  display: flex;
  margin: auto;
}

.slides img {
  background-color: rgba(27, 27, 27, 0.55);
  background-blend-mode: soft-light;
}

.slides .caption h3 {
  position: relative;
  top: 110px;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
}

.slides h3 {
  margin: 0;
}

.slider .indicators .indicator-item.active {
  background-color: #b3831d;
}

.menu-list .header-menu {
  background: url(/static/media/3.afd645b3.jpg) center center;
  -webkit-filter: hue-rotate(0deg);
  filter: hue-rotate(0deg);
  background-size: cover;
  z-index: -1;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: soft-light;
  height: 50vh;
}

.menu-list .header-menu .header-title h1 {
  color: white;
  font-size: 40px;
  letter-spacing: 0.3em;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
}

.menu-list #menu {
  position: relative;
  padding: 20px;
  width: 80%;
  max-width: 1100px;
  /* background: rgb(179,131,29); */
  margin: 0px auto;
  /* -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4); */
  z-index: 1;
  margin-bottom: 50px !important;
}
/* .menu-list #menu img {
   -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4); 
} */
.menu-list #menu .carousel {
  height: auto !important;
}

/* Food header */
.menu-list h1 {
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  margin: 1em 0 0.5em;
  color: black;
  font-size: 2.5em;
}

.menu-list .impossible {
  text-align: center;
}

.menu-list .menu {
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 1em;
  max-width: 960px;
  height: 100%;
  color: black;
}



/* food description */
.menu-list .menu .menu-item {
  display: flex;
  width: 50%;
  list-style-type: none;
  padding: 0 1em;
  margin: 0 0 2em;
  color: black;
}

.menu-list .menu .menu-item section {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.menu-list .menu .menu-item section header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.menu-list .menu .menu-item section header .spacer {
  content: '';
  width: 100%;
  height: .75em;
  margin: 0 .5rem;
  border-bottom: 2px dotted #999;
  flex: 1 1;
}

/* Food Item */
.menu-list .menu .menu-item section header h2 {
  margin: 0;
  font-size: 1rem;
  text-transform: uppercase;
}

.food-paragraph {
  color: black;
  text-align: center;
}

hr.style-divider {
  overflow: visible;
  /* For IE */
  padding: 0;
  border: none;
  border-top: medium double #b3831d;
  color: #b3831d;
  text-align: center;
  margin-bottom: 1em;
  position: relative;
  bottom: 1.25em;
}

hr.style-divider:after {
  content: "§";
  display: inline-block;
  position: relative;
  top: -0.9em;
  font-size: 2.5em;
  padding: 0 0.25em;
  background: #525f56;
}

/**
 * Fancy border
 * ============
*/
.fancy-border {
  border: 35px solid black;
  -webkit-border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23B88846' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23B88846'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E") 26;
          border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='75' height='75'%3E%3Cg fill='none' stroke='%23B88846' stroke-width='2'%3E%3Cpath d='M1 1h73v73H1z'/%3E%3Cpath d='M8 8h59v59H8z'/%3E%3Cpath d='M8 8h16v16H8zM51 8h16v16H51zM51 51h16v16H51zM8 51h16v16H8z'/%3E%3C/g%3E%3Cg fill='%23B88846'%3E%3Ccircle cx='16' cy='16' r='2'/%3E%3Ccircle cx='59' cy='16' r='2'/%3E%3Ccircle cx='59' cy='59' r='2'/%3E%3Ccircle cx='16' cy='59' r='2'/%3E%3C/g%3E%3C/svg%3E") 26;
}

@media only screen and (max-width: 600px) {
  .menu-list #menu {
    padding: 0px;
    width: 100%;
  }
  .menu-list .menu .menu-item {
    width: 100%;
    text-align: center;
  }
  .menu-list #menu {
    margin: 0px;
  }
  .slides .caption h3 {
    position: relative;
    top: 90px;
  }
  .tabs {
    margin-bottom: 0 !important;
  }
  .tabs .tab a {
    font-size: 12px !important;
  }
  .tabs li a {
    letter-spacing: 0 !important;
  }
  .tabs {
    flex-wrap: wrap;
  }
  .tabs .indicator {
    background-color: none !important;
    display: none;
  }
}

.catering-menu-text {
  font-size: 16px;
  color: #000;
  padding: 0 16px;
}
/*# sourceMappingURL=MenuList.css.map */
.careers .header-careers {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.careers {
  color: #A8903F;
  /************   New change  ***************/
}

.careers .header-careers {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
  background: url(/static/media/20.0711accd.jpg) center center;
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
  background-size: cover;
  z-index: -1;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: soft-light;
  height: 50vh;
}

.careers .header-careers .header-title h1 {
  color: white;
  font-size: 40px;
  letter-spacing: 0.3em;
  margin: 0 !important;
}

.careers .form {
  padding: 100px;
}

.careers .application {
  padding: 15px 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  letter-spacing: 0.2em;
  border-radius: 5px;
  border: 1px solid #928b75;
  box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0.75);
  color: #fff;
  background: #928b75;
  display: block;
  width: 40%;
  margin-bottom: 10px;
}

.careers .application:hover {
  border: 1px solid #928b75;
  color: #fff !important;
  box-shadow: 0 20px 50px -14px rgba(0, 0, 0, 0);
  font-weight: normal;
  background: white;
}

@media screen and (max-width: 700px) {
  .careers .application {
    display: block;
    width: 100%;
  }
}
/*# sourceMappingURL=Form.css.map */
.careers__container {
  padding: 15px 30px;
  width: 100%; }

.careers__title {
  text-align: center; }

.careers__name, .careers__availability--days, .careers__highschool,
.careers__college,
.careers__signature, .careers__highschoolStatus,
.careers__collegeStatus,
.careers__persons--info,
.careers__employment,
.careers__positions {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(1, 100%);
  margin-bottom: 30px; }

.careers__availability--date,
.careers__haveApplied,
.careers__legallyAllowedToWorkInUS,
.careers__work--company {
  margin-bottom: 30px; }

.careers__availability--days {
  margin-bottom: 30px; }

.careers__availability--day {
  display: inline-grid;
  grid-row-gap: 5px; }

.careers__availability--times {
  margin-left: 10px;
  font-style: italic;
  font-size: 13px;
  display: inline-block; }

.careers__haveApplied > :first-child,
.careers__legallyAllowedToWorkInUS > :first-child {
  margin-right: 40px; }

.careers__highschoolStatus,
.careers__collegeStatus {
  grid-template-columns: repeat(3, 33.3%); }

.careers__submitMessage {
  font-size: 25px;
  color: white;
  text-align: center;
  background: transparent;
  box-shadow: none;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

@media (min-width: 600px) {
  .careers__container {
    padding: 30px 50px; }
  .careers__name, .careers__availability--days, .careers__highschool,
  .careers__college,
  .careers__signature, .careers__highschoolStatus,
  .careers__collegeStatus,
  .careers__persons--info,
  .careers__employment,
  .careers__positions {
    grid-template-columns: repeat(2, 50%); } }

@media (min-width: 800px) {
  .careers__container {
    padding: 30px 100px; } }

@media (min-width: 1000px) {
  .careers__container {
    padding: 30px 150px; } }

.contact-section .header-contact {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.contact-section .header-contact {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
  background: url(/static/media/21.3dc1ba1e.jpg) center center;
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
  background-size: cover;
  z-index: -1;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: soft-light;
  height: 50vh;
}

.contact-section .header-contact .header-title h1 {
  color: white;
  font-size: 40px;
  letter-spacing: 0.3em;
}

.contact-section .section {
  padding: 0 !important;
  border-bottom: 1px dotted white;
}

.contact-section .section .container {
  width: 50%;
  padding: 50px;
}

.contact-section .section .row {
  margin: 0;
  /* label color */
}

.contact-section .section .row .submit {
  position: relative;
  top: 19px;
  padding: 15px 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid #928b75;
  background: #928b75;
  color: #fff;
  letter-spacing: 0.3em;
  width: 100%;
}

.contact-section .section .row .submit:hover {
  border: 1px solid #928b75;
  color: #928b75 !important;
  font-weight: normal;
  background: white;
}

.contact-section .section .row .message {
  height: 200px;
  width: 97%;
  position: relative;
  left: 1.5%;
}

.contact-section .section .order {
  background: transparent;
  width: 100%;
  padding: 25px;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  color: white;
  border: none;
  letter-spacing: 0.3em;
  font-size: 20px;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
}

.contact-section .section .order:hover {
  background: #b6aa89;
  color: white;
  cursor: pointer;
}
/*# sourceMappingURL=ContactSection.css.map */
.contact__container {
  padding: 50px;
  margin: 0 auto;
}

.contact__container p {
  font-size: 20px;
  color: #A8903F;
}

.contact__message {
  height: 200px !important;
  margin: 20px 0;
  padding: 10px;
}

.submit {
  padding: 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  letter-spacing: 0.2em;
  border-radius: 5px;
  border: 1px solid #928b75;
  box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0.75);
  color: #fff;
  background: #928b75 !important;
  display: block;
  width: 40%;
  margin-bottom: 10px;
}

.submit:hover, .submit:focus {
  padding: 15px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  letter-spacing: 0.2em;
  border-radius: 5px;
  border: 1px solid #928b75;
  box-shadow: 0 20px 30px -14px rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 600px) {
  .submit {
    display: block;
    width: 100%;
  }
}
/*# sourceMappingURL=ContactStyles.css.map */
.privacy-section .header-privacy {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.privacy-section {
  color: white;
  background: #f7f7f7 !important;
}

.privacy-section .header-privacy {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
  background: url(/static/media/2.bccaeb96.jpg) center center;
  background-size: cover;
  z-index: -1;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: soft-light;
  height: 50vh;
}

.privacy-section .header-privacy .header-title h1 {
  color: white;
  font-size: 40px;
  letter-spacing: 0.3em;
}

.privacy-section .section {
  padding: 50px !important;
  color: #212121;
  background: #f7f7f7 !important;
}

.privacy-section .section .row {
  margin: 0;
}
/*# sourceMappingURL=PrivacyInfo.css.map */
.promotions .header-promotions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.promotions {
  text-align: center !important;
  margin: 0 auto;
  color: #A8903F;
}

.promotions .header-promotions {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
  background: url(/static/media/2.bccaeb96.jpg) center center;
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
  background-size: cover;
  z-index: -1;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: soft-light;
  height: 50vh;
}

.promotions .header-promotions .header-title h1 {
  color: white;
  font-size: 40px;
  letter-spacing: 0.3em;
  margin: 0 !important;
}

.promotions .submit {
  position: relative;
  top: 19px;
  padding: 15px 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  border: 1px solid #928b75;
  background: #928b75;
  color: #fff;
  letter-spacing: 0.3em;
  width: 100%;
}

.promotions .submit:hover {
  border: 1px solid #928b75;
  color: #928b75 !important;
  font-weight: normal;
  background: white;
}

.promotions .section {
  padding: 40px 0 30px 0px !important;
}

.promotions .section .input-field input[type=text] + label, .promotions .section .materialize-textarea:focus:not([readonly]) + label {
  color: #A8903F !important;
  padding: 0 10px;
}

.promotions .section .input-field input[type=text], .promotions .section .materialize-textarea:focus:not([readonly]) {
  border: 1px solid #A8903F !important;
  box-shadow: 0 1px 0 0 #A8903F !important;
  border-radius: 5px;
  padding: 0 0 0 10px;
  margin-top: 5px;
}

.promotions .section .input-field input[type=text]:focus + label, .promotions .section .materialize-textarea:focus:not([readonly]) + label {
  color: transparent !important;
}

.promotions .section .input-field input[type=text]:focus, .promotions .section .materialize-textarea:focus:not([readonly]) {
  border: 1px solid #928b75 !important;
  box-shadow: 0 1px 0 0 #928b75 !important;
  padding: 0 0 0 10px;
}

.promotions .section .input-field input[type=email] + label, .promotions .section .materialize-textarea:focus:not([readonly]) + label {
  color: #A8903F !important;
  padding: 0 10px;
}

.promotions .section .input-field input[type=email], .promotions .section .materialize-textarea:focus:not([readonly]) {
  border: 1px solid #A8903F !important;
  box-shadow: 0 1px 0 0 #A8903F !important;
  border-radius: 5px;
  padding: 0 0 0 10px;
  margin-top: 5px;
}

.promotions .section .input-field input[type=email]:focus + label, .promotions .section .materialize-textarea:focus:not([readonly]) + label {
  color: transparent !important;
}

.promotions .section .input-field input[type=email]:focus, .promotions .section .materialize-textarea:focus:not([readonly]) {
  border: 1px solid #928b75 !important;
  box-shadow: 0 1px 0 0 #928b75 !important;
  padding: 0 0 0 10px;
}

@media screen and (max-width: 700px) {
  .promotions .submit {
    display: block;
    width: 100%;
  }
}
/*# sourceMappingURL=EmailForm.css.map */
.footer .footer-distributed .footer-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.footer-bottom {
  background-color: #ebebeb;
  color: #323e37;
  letter-spacing: 0.3em;
}

.footer-bottom .row, .footer-bottom .col {
  margin: 0 !important;
  padding: 0 !important;
}

.footer-bottom a {
  color: #323e37;
  font-weight: bold;
}

.footer {
  /* Footer left */
  /* The company logo */
  /* Footer links */
  /* Footer Center */
  /* Footer Right */
  /* If you don't want the footer to be responsive, remove these media queries */
}

.footer .footer-header {
  color: white;
  font-size: 22px;
  padding: 13px;
  letter-spacing: 0.3em;
  background: #AFA78D;
}

.footer .footer-logo {
  width: 70%;
  height: auto;
}

.footer .footer-distributed {
  background: #f1f1f1 !important;
  width: 100%;
  text-align: left;
  font: 21px;
  padding: 60px 0px;
}

.footer .footer-distributed .footer-left,
.footer .footer-distributed .footer-center,
.footer .footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

.footer .footer-distributed .footer-left {
  width: 33.3%;
  position: relative;
  top: -15px;
  padding: 0 0px 0 180px;
}

.footer .footer-distributed .footer-left p {
  color: #A8903F;
  text-align: center;
  border-bottom: 1px dotted #928B75;
  padding-bottom: 10px;
}

.footer .footer-distributed .footer-left .signup-btn, .footer .footer-distributed .footer-left .privacy-btn, .footer .footer-distributed .footer-left .careers-btn, .footer .footer-distributed .footer-left .menu-btn {
  display: inline-block;
  width: 80%;
  text-align: center;
  border: 1px solid #A8903F;
  left: 10%;
  position: relative;
  font-size: 13px;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.footer .footer-distributed .footer-left .signup-btn:hover, .footer .footer-distributed .footer-left .privacy-btn:hover, .footer .footer-distributed .footer-left .careers-btn:hover, .footer .footer-distributed .footer-left .menu-btn:hover {
  box-shadow: 0 20px 50px -14px rgba(0, 0, 0, 0.75);
  background: #928b75;
  border: 1px solid #928b75;
}

.footer .footer-distributed .footer-left a {
  color: #A8903F;
}

.footer .footer-distributed .footer-left a:hover {
  color: #fff !important;
}

.footer .footer-distributed h3 {
  color: #616161;
  font: 16px;
  margin: 0;
}

.footer .footer-distributed h3 span {
  color: #A8903F;
}

.footer .footer-distributed .footer-links {
  color: #616161;
  margin: 20px 0 12px;
  padding: 0;
}

.footer .footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}

.footer .footer-distributed .footer-company-name {
  color: #616161;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.footer .footer-distributed .footer-center {
  width: 33.3%;
  padding: 0 70px;
}

.footer .footer-distributed .footer-center i {
  background-color: transparent;
  color: #A8903F;
  border: 1px solid #A8903F;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 42px;
  margin: 10px 15px;
  padding: 5px 0 0 11px;
  vertical-align: middle;
}

.footer .footer-distributed .footer-center i.fa-map-marker {
  font-size: 25px;
  line-height: 38px;
  padding-left: 15px;
}

.footer .footer-distributed .footer-center i.fa-envelope {
  font-size: 26px;
  line-height: 38px;
}

.footer .footer-distributed .footer-center i.fa-phone {
  font-size: 25px;
  line-height: 38px;
}

.footer .footer-distributed .footer-center p {
  display: inline-block;
  color: #616161;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-distributed .footer-center p span {
  display: block;
  font-size: 16px;
  line-height: 2;
}

.footer .footer-center span {
  text-align: center;
  display: block;
  color: #A8903F;
  letter-spacing: 0.2em;
  border-bottom: 1px dotted #928B75;
  padding-bottom: 10px;
}

.footer .footer-distributed .footer-center p a {
  color: #A8903F;
  text-decoration: none;
}

.footer .footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 16px;
  left: 0;
  color: #616161;
  display: inline-block;
  padding-left: 11px;
  padding-right: 11px;
}

.footer .footer-distributed .footer-links .link-1:before {
  content: none;
}

.footer .footer-distributed .footer-right {
  width: 33.3%;
  padding: 0 180px 0 0px;
}

.footer .footer-distributed .footer-company-about {
  line-height: 20px;
  color: #616161 !important;
  font-size: 15px;
  margin: 0;
}

.footer .footer-distributed .footer-company-about span {
  display: block;
  color: #A8903F !important;
  font-size: 15px;
  margin-bottom: 11px;
  letter-spacing: 0.2em;
  text-align: center;
  border-bottom: 1px dotted #928B75;
  padding-bottom: 13px;
}

.footer .footer-distributed .footer-icons {
  text-align: center;
}

.footer .footer-distributed .footer-icons span {
  color: #A8903F;
  font-size: 15px;
  letter-spacing: 0.2em;
  border-bottom: 1px dotted #928B75;
  padding-bottom: 10px;
  width: 100%;
  display: block;
}

.footer .footer-distributed .footer-icons a {
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.5);
  display: inline-block;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 100%;
  padding: 7px 8px 8px 8px;
  font-size: 20px;
  color: #A8903F;
  border: 1px solid #A8903F;
  text-align: center;
  line-height: 35px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-right: 4px;
  margin-bottom: 5px;
}

.footer .footer-distributed .footer-icons a:hover {
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.75);
  background-color: white;
  border-radius: 100%;
  border: 1px solid #928b75;
  background: #928b75;
  color: white !important;
}

@media (max-width: 600px) {
  .footer .footer-bottom p {
    font-size: 13px;
  }
  .footer .footer-distributed {
    padding: 60px 0 20px !important;
  }
}

@media (max-width: 700px) {
  .footer .footer-distributed .footer-left {
    padding: 0px !important;
  }
  .footer .signup-btn, .footer .privacy-btn, .footer .careers-btn {
    width: 100%;
    left: 0% !important;
  }
}

@media (max-width: 880px) {
  .footer .signup-btn, .footer .privacy-btn, .footer .careers-btn {
    width: 50%;
    left: 0% !important;
  }
  .footer .footer .footer-center span {
    margin-bottom: 10px !important;
  }
  .footer .footer-distributed .footer-left,
  .footer .footer-distributed .footer-center,
  .footer .footer-distributed .footer-right {
    padding: 0 !important;
  }
  .footer .promotions {
    padding-top: 35px;
    background: transparent !important;
  }
  .footer .footer-center span {
    margin-bottom: 10px;
  }
  .footer .footer-distributed .footer-left,
  .footer .footer-distributed .footer-center,
  .footer .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    padding: 0 70px;
    border: none;
    text-align: center;
  }
  .footer .footer-distributed .footer-center i {
    display: none;
  }
  .footer .footer-distributed .footer-center p {
    font-size: 15px;
    text-align: center;
  }
  .footer .footer-distributed .footer-links {
    color: #616161;
    margin: 20px 0px;
    width: 100%;
  }
}
/*# sourceMappingURL=Footer.css.map */
.success .header-success {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.success {
  text-align: center !important;
  margin: 0 auto;
  color: #A8903F;
  background: #f7f7f7 !important;
  font-size: 24px;
}

.success .header-success {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
  background: url(/static/media/20.0711accd.jpg) center center;
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
  background-size: cover;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: soft-light;
  height: 100vh;
}

.success .header-success .header-title h1 {
  color: white;
  font-size: 60px;
  letter-spacing: 0.3em;
  margin: 0 !important;
}
/*# sourceMappingURL=Success.css.map */
.tabs {
  margin: 40px 0 25px;
  height: 100%;
  /*Color of underline*/
}

.tabs li a {
  color: #A8903F !important;
  background: transparent !important;
}

.tabs .indicator {
  background-color: #A8903F !important;
}

.tabs .tab a.active {
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.151);
}

.section-gallery {
  background: #fff;
  margin: 0 15px 50px 15px;
  margin: 0 auto;
  padding: 0 0 50px;
}

.section-gallery .tabs-content.carousel.carousel-slider {
  height: 630px !important;
}

.section-gallery .gallery__row {
  background: #fff;
}

.section-gallery .gallery__row .col, .section-gallery .gallery__row .m3 {
  padding: 0px 5px !important;
}

.section-gallery .gallery__row .box {
  position: relative;
  width: 100%;
  height: 200px;
  margin: 5px 0;
  overflow: hidden;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  border-radius: 3px;
}

.section-gallery .gallery__row .box:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 0.8;
}

.section-gallery .gallery__row .box .react_lightgallery_item .gallery__image {
  width: 700px;
  height: auto;
  position: absolute;
  -webkit-transition: -webkit-transform 1.5s;
  transition: -webkit-transform 1.5s;
  transition: transform 1.5s;
  transition: transform 1.5s, -webkit-transform 1.5s;
}

@media (max-width: 600px) {
  .box {
    height: 150px !important;
  }
  .col {
    overflow-y: scroll;
    height: auto;
    -webkit-overflow-scrolling: touch;
  }
  .gallery__image {
    width: 130% !important;
  }
  .section-gallery .tabs-content.carousel.carousel-slider {
    height: 465px !important;
  }
}
/*# sourceMappingURL=LightGalleryStyles.css.map */
.error .header-error {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.error {
  text-align: center !important;
  margin: 0 auto;
  color: #A8903F;
  background: #f7f7f7 !important;
  font-size: 24px;
}

.error .header-error {
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4), 0px 4px 7px rgba(0, 0, 0, 0.1), 0px 9px 12px rgba(0, 0, 0, 0.1);
  background: url(/static/media/20.0711accd.jpg) center center;
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
  background-size: cover;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: soft-light;
  height: 100vh;
}

.error .header-error .header-title h1 {
  color: white;
  font-size: 60px;
  letter-spacing: 0.3em;
  margin: 0 !important;
}
/*# sourceMappingURL=Error.css.map */
.nav-mobile {
  position: fixed;
  z-index: 999;
  display: none;
  margin: 20px !important;
  background: #928b75;
  width: 50px;
  height: 50px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 25px;
  padding: 6px 5px 5px 14px;
  box-shadow: 0 20px 60px -14px rgba(0, 0, 0, 0.8);
}

.nav-mobile .order-online {
  position: absolute;
  background: #A8903F;
  font-size: 10px;
  height: 50px;
  font-weight: bold;
  top: 0;
  padding-left: 15px;
  padding-right: 10px;
  margin-left: 11px;
  box-shadow: 0 20px 60px -14px rgba(0, 0, 0, 0.8);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.nav-mobile .order-online-right {
  position: absolute;
  background: #A8903F;
  font-size: 10px;
  height: 50px;
  font-weight: bold;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  top: 0;
  padding-left: 15px;
  padding-right: 10px;
  margin-left: 105px;
  box-shadow: 0 20px 60px -14px rgba(0, 0, 0, 0.8);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.nav-mobile .order-online .order {
  letter-spacing: 0.45em;
}

.nav-mobile .order-online:hover {
  background: #928b75;
  font-weight: normal;
}

.nav-mobile ul li {
  height: calc(100vh/6);
  justify-content: center;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.096);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.nav-mobile ul li:hover {
  background: #AFA78D;
}

.nav-mobile a {
  color: white;
  letter-spacing: 0.3em;
}

.nav-mobile .sidenav {
  text-align: center;
  background: #928b75 !important;
  width: 70%;
}

.nav-mobile .sidenav a {
  color: white !important;
}

@media only screen and (max-width: 1000px) {
  .nav-mobile {
    display: block;
  }
}
/*# sourceMappingURL=NavMobile.css.map */
.scroll-to-top {
  position: fixed;
  background: #928b75;
  opacity: 0.5;
  width: 40px;
  height: 40px;
  bottom: 20px;
  right: 25px;
  border-radius: 100%;
  border: 1px #928b75 !important;
  border: none;
  display: none;
  padding: 27px;
  z-index: 9999;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.scroll-to-top i {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -13px;
  font-size: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.scrollToTop {
  display: block;
  -webkit-animation: fadein 2s;
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1s;
  /* Firefox < 16 */
  /* Safari, Chrome and Opera > 12.1 */
  /* Internet Explorer */
  /* Opera < 12.1 */
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

.scroll-to-top:hover {
  opacity: 1;
  box-shadow: 0 20px 60px -14px rgba(0, 0, 0, 0.75);
}
/*# sourceMappingURL=ScrollToTop.css.map */
#alert-message {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

#alert-message .closebtn {
  margin-left: 15px;
  color: #212121;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#alert-message .closebtn:hover {
  color: #535353;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
}

.popup {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  display: block;
  padding: 2em;
  min-width: 20em;
  max-width: 80%;
  color: #212121;
  background-color: #fff;
  border-radius: 0.8em;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  outline: transparent;
}

.popup-btn {
  border: 1px solid #212121;
  width: 100%;
  padding: 3px;
  border-radius: 0.3em;
  text-align: center;
  margin-top: 10px;
}
/*# sourceMappingURL=Alert.css.map */
